type TokenType = {
  value: string;
  expiry: number;
};

export function addToLocalStorage(key: string, localValue: TokenType) {
  const now = new Date();

  // item object will store the value and the expiry time
  const valueObjWithExpiry = {
    value: localValue,
    expiry: now.getTime() + 24 * 3600 * 1000, // 24 hours in milliseconds
  };

  localStorage.setItem(key, JSON.stringify(valueObjWithExpiry));
}

export function getValueFromLocalStorage(key: string) {
  const itemStr = localStorage.getItem(key);

  // If item does not exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // Compare the expiry time of the item with the current time
  if (now.getTime() > item?.expiry) {
    // If the item has expired, delete the item from storage and return null
    localStorage.removeItem(key);
    return null;
  }

  return item?.value;
}

export function removeFromLocalStorage(key: string) {
  localStorage.removeItem(key);
}

export function clearAllLocalStorage() {
  localStorage.clear();
}
