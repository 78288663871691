// eslint-disable-next-line import/no-extraneous-dependencies
import { io, Socket } from "socket.io-client";
import { getValueFromLocalStorage } from "@utils/local-storage";

const url: string = process.env.REACT_APP_QUALI_SOCKET_URL!;
const socketClient = io(url, {
  transports: ["websocket"],
  autoConnect: true,
  auth: {
    token: `JWT ${getValueFromLocalStorage("token")}`,
  },
}) as Socket;
export default socketClient;
