import React from "react";
import { ISvgProps } from "@components/Svg/interface";

interface ICrossSvgProps extends ISvgProps {
  crossFill?: string;
}

export default function CrossIconSvg({
  width = "25",
  height = "25",
  crossFill = "#000",
  onClick,
  className,
}: ICrossSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.20125 24.1225C1.81072 24.513 1.17756 24.513 0.787034 24.1225C0.39651 23.732 0.39651 23.0988 0.787034 22.7083L11.0405 12.4548L0.787034 2.20135C0.39651 1.81083 0.39651 1.17766 0.787034 0.787138C1.17756 0.396614 1.81072 0.396614 2.20125 0.787138L12.4547 11.0406L22.7083 0.787034C23.0988 0.39651 23.732 0.39651 24.1225 0.787034C24.513 1.17756 24.513 1.81072 24.1225 2.20125L13.8689 12.4548L24.1225 22.7084C24.513 23.0989 24.513 23.7321 24.1225 24.1226C23.732 24.5131 23.0988 24.5131 22.7083 24.1226L12.4547 13.869L2.20125 24.1225Z"
        fill={crossFill}
      />
    </svg>
  );
}
