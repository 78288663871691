// NO USE OF THIS CODE IN THE PROJECT

import React from "react";
import jwt_decode from "jwt-decode";

// * utils
import {
  IPermissionsProviderProps,
  IPermissionsContext,
  IToken,
} from "@contexts/PermissionContext/interfaces";
import { deleteAllCookies } from "@utils/cookies";
import { getValueFromLocalStorage } from "@utils/local-storage";

// * initialize the context
const PermissionContext = React.createContext<IPermissionsContext>({
  permissions: [],
});

export function PermissionsProvider({ children }: IPermissionsProviderProps) {
  const [permissions, updatePermissions] = React.useState<String[]>([]);
  const value = React.useMemo(() => ({ permissions }), [permissions]);

  /**
   * * updates the permission of the user based on the token
   * * received from the login response and updates the user
   * * premissions on each login.
   */
  const updatePermissionsFromToken = () => {
    const token = getValueFromLocalStorage("token");
    if (token) {
      try {
        const decodedToken: IToken = jwt_decode(token);
        if (decodedToken) {
          const permissionArray: String[] = decodedToken.permissions;
          updatePermissions(permissionArray);
        }
      } catch (e) {
        deleteAllCookies();
      }
    }
  };

  React.useEffect(() => {
    updatePermissionsFromToken();
  }, [getValueFromLocalStorage("token")]);

  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
}

// eslint-disable-next-line import/prefer-default-export
export function getUserPermissions() {
  const permissions = getValueFromLocalStorage("userPermissions");
  return permissions;
}
