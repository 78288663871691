/* eslint-disable @typescript-eslint/no-shadow */
import React, { createContext, useReducer } from "react";
import Notification from "@components/Notification";

// * utils
import {
  PUSH_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from "@components/Notification/NotificationTypes";
import { INotificationProps } from "@components/Notification/interface";
import { INotificationAction } from "@contexts/NotificationContext/interface";

export const NotificationContext = createContext<any>([]);

export default function NotificationProvider({ ...props }: any) {
  // * initialising the notification array using useReducer hook
  const [state, dispatch] = useReducer(
    (state: any, action: INotificationAction) => {
      /**
       * * this helps us decide eigther add a new notification
       * * or remove a notification
       * @param actionType * decides which case to run
       */
      switch (action.type) {
        // * appends a new notification
        case PUSH_NOTIFICATION: {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          handleRemoveNotification(action.payload.id, 5000);
          return [...state, action.payload];
        }

        // * removes a desired notification
        case REMOVE_NOTIFICATION: {
          return state.filter(
            (toast: INotificationAction) => toast.id !== action.payload.id
          );
        }

        // * return notification array as default
        default:
          return state;
      }
    },
    []
  );

  /**
   * * remove a notification before expiry.
   * @param id * the Id of the notification
   * @param duration * dynamic duration for set timeout
   */
  function handleRemoveNotification(id: number, duration: number) {
    setTimeout(() => {
      dispatch({
        type: REMOVE_NOTIFICATION,
        payload: {
          id,
        },
      });
    }, duration);
  }

  return (
    <NotificationContext.Provider value={dispatch}>
      {state.length > 0 && (
        <div className="fixed z-10 top-[10%] right-0 w-full max-w-[500px]">
          <div className="h-full max-h-[90vh] overflow-y-auto scrollbar overflow-x-hidden">
            {state.map((notification: INotificationProps) => {
              return (
                <div className="p-2" key={notification.id}>
                  <Notification
                    type={notification.type}
                    message={notification.message}
                    click={() => handleRemoveNotification(notification.id, 500)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {props.children}
    </NotificationContext.Provider>
  );
}
