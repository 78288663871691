import { PING_RESOURCE_URL, REQUEST_TIMEOUT } from "@utils/constants";
import permissions from "./permissionConfig";
import { LeadStatus } from "@components/Status/interface";

interface Dictionary<T> {
  [Key: string]: T;
}

export const timeoutUnresolvedRequest = (
  time: number,
  promise: Promise<unknown>
) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error("Request timed out."));
    }, time);

    promise.then(resolve, reject);
  });
};

export const checkInternetConnection = async () => {
  const controller = new AbortController();
  const { signal } = controller;

  if (!navigator?.onLine) {
    return false;
  }

  try {
    await timeoutUnresolvedRequest(
      REQUEST_TIMEOUT,
      fetch(PING_RESOURCE_URL, {
        method: "GET",
        signal,
      })
    );

    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    controller.abort();
  }

  return false;
};

export const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, time);
  });

export function fetchPermittedRoute(validPermissions: string[]) {
  const userPermissions = new Set(validPermissions);
  const routesPermissionMap: Dictionary<string[]> = {
    "/upload-uq-leads": permissions.upload_uq_leads.view.permission,
    "/filter-and-download": permissions.filter_and_download.view.permission,
    "/qualify": permissions.qualify.view.permission,
    "/leadbook": permissions.leadbook.view.permission,
    "/reconfirmation": permissions.reconfirmation.view.permission,
  };

  const permittedRoute = Object?.entries(routesPermissionMap)?.find(
    ([, value]) => userPermissions?.has(value[0])
  );

  return permittedRoute ? permittedRoute?.[0] : "/";
}

export function formatLeadStatus(status: string) {
  switch (status) {
    case LeadStatus.INV:
      return "Invalid";
    case LeadStatus.UQL:
      return "Unqualified";
    case LeadStatus.BL:
      return "Blacklisted";
    case LeadStatus.CP:
      return "Call Pending";
    case LeadStatus.QL:
      return "Qualified";
    case LeadStatus.ABL:
      return "Appointment Booked";
    case LeadStatus.NIBM:
      return "NIBM";
    case LeadStatus.NIAM:
      return "NIAM";
    case LeadStatus.MOVING_TO_CX_PLACE:
      return "Moving to Cx place";
    case LeadStatus.CX_MET:
      return "Cx met";
    case LeadStatus.SALES_FOLLOWUP:
      return "Sales Followup";
    case LeadStatus.MAY_TXN:
      return "May TXN";
    case LeadStatus.MAY_TXN_RNR:
      return "May TXN RNR";
    case LeadStatus.MAY_TXN_RESCHEDULED:
      return "May TXN Rescheduled";
    case LeadStatus.TXN_RAISED:
      return "TXN Raised";
    case LeadStatus.SUCCESS:
      return "Success";
    case LeadStatus.FAILED_TO_SEND:
      return "Failed to send";
    case LeadStatus.VISIT_CANCELLED_ANRC:
      return "Cancelled - ANRC";
    case LeadStatus.VISIT_CANCELLED_NIAM:
      return "Cancelled - NIAM";
    case LeadStatus.VISIT_CANCELLED_NIBM:
      return "Cancelled - NIBM";
    case LeadStatus.VISIT_CONFIRMED:
      return "Visit Confirmed";
    case LeadStatus.VISIT_ASSIGNED:
      return "Visit Assigned";
    case LeadStatus.VISIT_IN_PROGRESS:
      return "Visit in Progress";
    case LeadStatus.VISIT_RESCHEDULED:
      return "Visit Rescheduled";
    case LeadStatus.SALES_RNR:
      return "Sales RNR";
    case LeadStatus.VISIT_CANCELLED:
      return "Cancelled";
    case LeadStatus.VISIT_CANCELLED_IE:
      return "Cancelled - IE";
    case LeadStatus.VISIT_CANCELLED_GS:
      return "Cancelled - GS";
    case LeadStatus.VISIT_COMPLETED_BRL:
      return "Completed - BRL";
    case LeadStatus.VISIT_COMPLETED_GL:
      return "Completed - GL";
    case LeadStatus.BRL_VISIT:
      return "BRL Visit";
    case LeadStatus.VISIT_CANCELLED_GL:
      return "Cancelled - GL";
    case LeadStatus.VISIT_CANCELLED_BRL:
      return "Cancelled - BRL";
    case LeadStatus.QL_WEBSITE:
      return "QL - Website";
    case LeadStatus.QL_CREF:
      return "QL - CREF";
    case LeadStatus.QL_ORGANIC:
      return "QL - Organic";
    case LeadStatus.QL_SUB:
      return "QL - Submitted";
    case LeadStatus.QL_DIGITAL:
      return "QL - Digital";
    case LeadStatus.DIRECT_GL:
      return "Direct - GL";
    case LeadStatus.DIRECT_BRL:
      return "Direct - BRL";
    case LeadStatus.DUP:
      return "Duplicate";

    default:
      return "";
  }
}

export const getFormattedStatus = (status: string) => {
  const prevStatus = status?.split("-");
  if (prevStatus?.length > 1) {
    return `${prevStatus[0]} - ${prevStatus[1].toUpperCase()}`;
  }
  return status;
};

export const isLeadSourceACustomer = (leadSource: string) => {
  // Check if the input starts with OROLEAD followed by any number of digits
  const match = leadSource.match(/^OROLEAD(\d+)$/);

  if (match) {
    // If there's a match, extract the number
    const leadNumber = match[1];
    // Return the formatted URL
    return `${process.env.REACT_APP_QUALI_WEB_APP_URL}/leadbook/view/${leadNumber}`;
  }

  // If the input doesn't match the format, return null
  return null;
};
