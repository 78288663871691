import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import axios, { AxiosError } from "axios";
// * services
import useFetchLeadDetails from "@services/Leadbook/fetchLeadDetails";
// * contexts
import { NotificationContext } from "@contexts/NotificationContext";
// * helpers
import errorHandling from "@services/helpers/helpers";
// * types
import {
  PostABLDetailsType,
  PreABLDetailsType,
} from "@services/Leadbook/interface";

type ILeadDetailsContextType = {
  preABLDetails?: PreABLDetailsType;
  postABLDetails?: PostABLDetailsType;
  fetchingDetails: boolean;
  fetchData: (id: string) => void;
};
export const LeadDetailsContext = createContext<ILeadDetailsContextType>({
  preABLDetails: undefined,
  postABLDetails: undefined,
  fetchingDetails: false,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  fetchData: (id: string) => {},
});

export function LeadDetailsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [preABLDetails, setPreABLDetails] = useState<PreABLDetailsType>();
  const [postABLDetails, setPostABLDetails] = useState<PostABLDetailsType>();
  const [fetchingDetails, setFetchingDetails] = useState(false);

  const dispatch = useContext(NotificationContext);
  const { handleLeadDetails } = useFetchLeadDetails();

  const fetchData = async (id: string) => {
    try {
      setFetchingDetails(true);
      const preAblResponse = await handleLeadDetails({
        url: `/api/v1/lead-profile/${Number(id)}/pre-abl`,
      });
      const postAblResponse = await handleLeadDetails({
        url: `/api/v1/lead-profile/${Number(id)}/post-abl`,
      });
      setPreABLDetails(preAblResponse?.data?.data || {});
      setPostABLDetails(postAblResponse?.data?.data || {});
    } catch (error) {
      if (!axios.isCancel(error)) {
        const errorMessage = errorHandling(error as AxiosError);
        dispatch(errorMessage);
      }
    }
  };

  useEffect(() => {
    if (
      Object.keys(preABLDetails ?? {})?.length &&
      Object.keys(postABLDetails ?? {})?.length
    )
      setFetchingDetails(false);
  }, [preABLDetails, postABLDetails]);

  const value = useMemo(
    () => ({ fetchingDetails, preABLDetails, postABLDetails, fetchData }),
    [preABLDetails, postABLDetails, fetchingDetails]
  );

  return (
    <LeadDetailsContext.Provider value={value}>
      {children}
    </LeadDetailsContext.Provider>
  );
}
