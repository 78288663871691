const PING_RESOURCE_URL = "/robots.txt";
const NETWORK_CHECK_POLLING_TIME = 10000;
const REQUEST_TIMEOUT = 120000;

enum LeadType {
  HighIntentLead = "HighIntentLead",
  GeneralLead = "GeneralLead",
}

enum HighIntentLeadTypes {
  ORGANIC = "ORGANIC",
  WEBSITE = "WEBSITE",
  DIGITAL = "DIGITAL",
  SUBMITTED = "SUBMITTED",
  CUSTOMER_REFERRAL = "REFERRALS",
}

const HighIntentLeadTitles = {
  [HighIntentLeadTypes.ORGANIC]: "Organic App Leads",
  [HighIntentLeadTypes.WEBSITE]: "Website Leads",
  [HighIntentLeadTypes.DIGITAL]: "Digital Leads",
  [HighIntentLeadTypes.SUBMITTED]: "Submitted Leads",
  [HighIntentLeadTypes.CUSTOMER_REFERRAL]: "Customer Referrals",
};

export {
  PING_RESOURCE_URL,
  NETWORK_CHECK_POLLING_TIME,
  REQUEST_TIMEOUT,
  LeadType,
  HighIntentLeadTypes,
  HighIntentLeadTitles,
};
