import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import verifyToken from "@services/Auth/verifyToken";
import { deleteAllCookies } from "@utils/cookies";
import { IPrivateRoutesProps } from "@routes/interface";
import {
  addToLocalStorage,
  clearAllLocalStorage,
  getValueFromLocalStorage,
} from "@utils/local-storage";

export default function PrivateRoutes({
  children,
}: IPrivateRoutesProps): JSX.Element {
  const [isVerified, setIsVerified] = useState(
    !!getValueFromLocalStorage("token")
  );
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const { handleFetchVerifyToken } = verifyToken();

  useEffect(() => {
    handleFetchVerifyToken()
      .then((response) => {
        if (!getValueFromLocalStorage("userPermissions")) {
          const userPermissions = response?.data?.user?.permissions;
          const userInfo: any = {
            id: response?.data?.user?.id,
            email: response?.data?.user?.email_id,
            firstName: response?.data?.user?.first_name,
            lastName: response?.data?.user?.last_name,
            role: response?.data?.user?.role_name,
          };

          addToLocalStorage("userPermissions", userPermissions);
          addToLocalStorage("userInfo", userInfo);
          window.location.reload();
          setIsVerified(true);
          setIsLoading(false);
        }
      })
      .catch((authError) => {
        //
        if (!axios.isCancel(authError)) {
          setIsVerified(false);
          deleteAllCookies();
          clearAllLocalStorage();
          setIsLoading(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [children]);

  // * fetch token from localstorage
  // const TOKEN = getValueFromCookie('token');

  /**
   * ? check if token and user data exist then render the component
   * ? else redirect to login
   *
   *
   */

  if (isLoading) {
    return <div />; // Display a loading state while verifying
  }

  return isVerified ? (
    children
  ) : (
    <Navigate
      to={{ pathname: "/login" }}
      state={{
        from: pathname,
      }}
    />
  );
}
