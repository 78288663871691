import React from "react";
import ReactDOM from "react-dom/client";
import Routes from "@routes/index";
import "@styles/index.css";
import "@styles/datepicker.css";

const rootElement = document.getElementById("root");

// a simple check if root exist or not
if (!rootElement) throw new Error("Failed to find the root element");

const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>
);
