import React, { useEffect, useState } from "react";

// * utils
import { INotificationProps } from "@components/Notification/interface";
import { ERROR, SUCCESS } from "@components/Notification/NotificationTypes";

// * assets
import CrossIconSvg from "@components/Svg/CrossIconSvg";
import WarningIconSvg from "@components/Svg/WarningIconSvg";
import DoneStatus from "@components/Svg/DoneStatus";

export default function Notification({
  type,
  message,
  click,
}: INotificationProps) {
  // * STATES
  const [dismissToast, setDismissToast] = useState(false);

  /**
   * * effect runs on every render of new notification
   * * auto slides the toast before disappearing
   */
  useEffect(() => {
    setTimeout(() => {
      setDismissToast(true);
    }, 4500);
  }, []);

  // * decides which icon to display based on notification type
  const renderNotificationIcon = () => {
    if (type === ERROR) {
      return (
        <div className="w-[25px] h-[25px] flex items-center justify-center rounded-full bg-primary-red">
          <CrossIconSvg width="10" height="10" crossFill="#fff" />
        </div>
      );
    }
    if (type === SUCCESS) {
      return <DoneStatus width="25" height="25" />;
    }
    return <WarningIconSvg width="25" height="25" />;
  };

  return (
    <div
      className={`flex items-stretch rounded-[8px] bg-white border-[0.5px] border-secondary-grey ${
        dismissToast
          ? "animate-slideOut translate-x-[500px]"
          : "animate-slideIn"
      }`}
    >
      <div className="px-5 flex items-center">{renderNotificationIcon()}</div>
      <div className="p-3 grow min-h-[60px] overflow-auto">
        <p className="text-base font-semibold leading-7 h-full flex items-center">
          {message}
        </p>
      </div>
      <div
        role="presentation"
        className="px-7 flex items-center rounded-r-[8px] cursor-pointer bg-secondary-grey"
        onClick={() => {
          setDismissToast(true);
          click();
        }}
      >
        <CrossIconSvg className="h-3 w-3 cursor-pointer" crossFill="#fff" />
      </div>
    </div>
  );
}
