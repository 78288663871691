import { useEffect, useState } from "react";
import useAxios from "@lib/initAxios";

export default function useFetchLeadDetails() {
  const [leadDetails, setLeadDetails] = useState();
  const [{ data: response, loading }, execute] = useAxios(
    {
      baseURL: process.env.REACT_APP_QUALI_URL,
      method: "GET",
    },
    { manual: true }
  );

  useEffect(() => {
    if (response) {
      setLeadDetails(response?.data);
    }
  }, [response]);

  return { loading, leadDetails, handleLeadDetails: execute };
}
