import { AxiosError } from "axios";
import {
  ERROR,
  PUSH_NOTIFICATION,
} from "@components/Notification/NotificationTypes";

/**
 * * handles error handling for apis
 * ? if error has a response then extract data from response and send message from response
 * ? else send somthing went wrong.
 * @param error error received in api calls
 * @returns notification payload based on the error
 */
export default function errorHandling(error: AxiosError) {
  if (error?.response) {
    const { data } = error.response;
    return {
      type: PUSH_NOTIFICATION,
      payload: {
        id: Math.floor(Math.random() * 100),
        type: ERROR,
        message: (data as { message: string }).message,
      },
    };
  }
  return {
    type: PUSH_NOTIFICATION,
    payload: {
      id: Math.floor(Math.random() * 100),
      type: ERROR,
      message: "Something went wrong",
    },
  };
}
