import React, { useState, createContext, useReducer } from "react";
import socketClient from "@lib/initSocketClient";
import SocketConnections from "@services/Socket/interface";

export const SocketContext = createContext<any>([]);

function SocketProvider({ ...props }: any) {
  const [map, setMap] = useState(new Map());
  const handleSubscribeEvent = (
    state: any,
    callback: any,
    endPoint: string,
    subscripId: string,
    req: any
  ) => {
    const listener = (data: any) => {
      const { event, data: payload, subscriptionId } = data;
      if (event === "data") {
        if (map.has(subscriptionId)) {
          callback(payload);
        }
      }
    };
    setMap(
      new Map(
        map.set(subscripId, {
          endPoint,
          listener,
        })
      )
    );

    socketClient.emit(endPoint, {
      event: "subscribe",
      data: req,
      subscriptionId: subscripId,
    });
    socketClient.on(endPoint, listener);
    return state;
  };

  const handleUnsubscribeEvent = (state: any, key: string, req: any) => {
    if (!map.has(key)) return state;
    const { endPoint, listener } = map.get(key);
    socketClient.emit(endPoint, {
      event: "unsubscribe",
      data: req,
      subscriptionId: key,
    });
    socketClient.off(endPoint, listener);
    map.delete(key);
    return state;
  };

  function reducer(state: any, action: any) {
    switch (action.type) {
      case SocketConnections.SUBSCRIBE: {
        const { callback, endPoint, subscriptionId, req } = action.payload;
        handleSubscribeEvent(state, callback, endPoint, subscriptionId, req);
        return state;
      }
      case SocketConnections.UNSUBSCRIBE: {
        const { subscriptionId, req } = action.payload;
        handleUnsubscribeEvent(state, subscriptionId, req);
        return state;
      }
      default: {
        return state;
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useReducer(reducer, []);

  return (
    <SocketContext.Provider value={dispatch}>
      {props.children}
    </SocketContext.Provider>
  );
}

export default SocketProvider;
