const permissions = {
  upload_uq_leads: {
    view: {
      permission: ["QUALI_FULL_ACCESS"],
    },
  },
  filter_and_download: {
    view: {
      permission: ["QUALI_FULL_ACCESS"],
    },
  },
  qualify: {
    view: {
      permission: ["QUALI_FULL_ACCESS"],
    },
  },
  leadbook: {
    view: {
      permission: ["QUALI_VIEW_LEADBOOK_TAB"],
    },
    table_row: {
      permission: ["QUALI_VIEW_LEADBOOK_TABLE"],
    },
    create: {
      permission: ["QUALI_VIEW_CREATE_LEAD"],
    },
    create_direct_visit: ["CREATE_NEW_MANUAL_VISIT"],
    create_direct_visit_fresh_loan: ["AGENT_CREATE_FRESH_LOAN_VISIT"],
    create_direct_visit_brl: ["AGENT_CREATE_BRL_VISIT"],
  },
  reconfirmation: {
    view: {
      permission: ["QUALI_FULL_ACCESS"],
    },
  },
  high_intent_leads: {
    view: {
      permission: ["QUALI_FULL_ACCESS"],
    },
  },
};

export default permissions;
